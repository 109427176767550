import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import Fade from "react-reveal/Fade";
import Loader from "./Loader";

function HomeComponent() {
  const form = useRef();
  const [isSuccess, setIsSuccess] = useState(false);
  const [imageKey, setImageKey] = useState(1); // Initialize with 1
  const [showLoader, setShowLoader] = useState(true);
  const [showMainSection, setShowMainSection] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
      setShowMainSection(true);
    }, 5000); // Adjust the delay as needed
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    setImageKey((prevKey) => prevKey + 1);
    const encryptedText = CryptoJS.AES.encrypt(
      uuidv4(),
      process.env.REACT_APP_SSK
    ).toString();

    const FD = new FormData(form.current);
    FD.append("accessToken", encryptedText);

    const obj = {
      message: FD.get("message"),
      userInfo: FD.get("userInfo"),
      from: FD.get("from"),
      subject: FD.get("subject"),
      tel: FD.get("tel"),
      ownerName: "info@polentech.pl",
      to: "info@polentech.pl",
      host: "smtp.gmail.com",
      service: "gmail",
      accessToken: encryptedText,
    };
    try {
      const res = await axios.post("https://nodemailer.enesatalay.com/", obj, {
        auth: {
          username: "OSICrew_Node_Mailler",
          password: "1786oSi?173",
        },
      });
      if (res) {
        setIsSuccess(true);
        console.log("dkda");
        toast.success("Mesajınız gönderildi.", { autoClose: 4500 });
        console.log(res);
      }
    } catch (error) {
      console.log(error);
      console.log(obj);
      toast.error(error.message, { autoClose: 4500 });
    }
  };
  useEffect(() => {
    setImageKey((prevKey) => prevKey + 1);
  }, []);

  return (
    <>
      <ToastContainer />
      {showLoader && <Loader />}
      {showMainSection && (
        <>
          <section className="main-hero-area pt-100 pb-80 rel z-1">
            <div className="container container-1620">
              <div className="row align-items-center">
                <Fade top>
                  {" "}
                  <div className="col-lg-2 col-sm-7">
                    <div className="hero-content rmb-55  ">
                      <span className="h3"> Energizing a </span>
                      <h1>
                        <b>Sustainable</b> Tomorrow
                      </h1>

                      <p>
                        We are with you with the latest energy technology
                        solutions
                      </p>
                      <div className="hero-btns">
                        <a href="/contact" className="theme-btn">
                          Contact
                          <i className="far fa-angle-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                </Fade>
                <Fade top>
                  <div className="col-lg-2 col-sm-5 order-lg-3">
                    <div className="hero-counter-wrap ms-lg-auto rmb-55 ">
                      <div className="counter-item counter-text-wrap">
                        <span
                          className="count-text percent"
                          data-speed={3000}
                          data-stop={99}
                        >
                          99
                        </span>
                        <span className="counter-title">Professionalism </span>
                      </div>
                      <div className="counter-item counter-text-wrap">
                        <span
                          className="count-text percent"
                          data-speed={3000}
                          data-stop={99}
                        >
                          99
                        </span>
                        <span className="counter-title">Performance</span>
                      </div>
                      <div className="counter-item counter-text-wrap">
                        <span
                          className="count-text percent"
                          data-speed={3000}
                          data-stop={99}
                        >
                          99
                        </span>
                        <span className="counter-title">
                          Client Satisfaction
                        </span>
                      </div>
                    </div>
                  </div>
                </Fade>

                <div className="col-lg-8">
                <div
  className="Sirv"

  data-src="https://etchurac.sirv.com/polentech/polentech.spin?zoom=1&fullscreen=false&hint=false&autospin=infinite"
></div>
                </div>
              </div>
            </div>
            <div className="bg-lines">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          </section>
          <section className="about-area rel z-1">
            <div className="for-bgc-black py-130 rpy-100">
              <div className="container">
                <div className="row gap-100 align-items-center">
                  <div className="col-lg-7">
                    <div className="about-content-part rel z-2 rmb-55">
                      <Fade bottom delay={200}>
                        <div className="section-title mb-35  ">
                          <span className="sub-title mb-15">About Us</span>

                          <h2>
                            Where <span>Innovation</span> Meets{" "}
                            <span>Dedication:</span> Polentech
                          </h2>
                          <p>
                            Polentech is a dynamic and innovative company
                            dedicated to delivering cutting-edge solutions to
                            meet the unique needs of our diverse clientele. With
                            a team of highly skilled professionals, we excel in
                            various sectors, including Information Technologies,
                            Software Solutions, Energy, Security, Engineering,
                            and Installation.
                          </p>
                        </div>
                      </Fade>
                      <Fade bottom delay={200}>
                        {" "}
                        <ul className="list-style-one two-column ">
                          <li>Energy</li>
                          <li>Technology</li>
                          <li>Innovation</li>
                          <li>R&D</li>
                        </ul>
                      </Fade>

                      <Fade bottom delay={200}>
                        {" "}
                        <div className="about-info-box mt-25 ">
                          <div className="info-box-item">
                            <i className="far fa-envelope" />
                            <div className="content">
                              <span>Email Us</span>
                              <br />
                              <a href="mailto:info@polentech.pl">
                                info@polentech.pl
                              </a>
                            </div>
                          </div>
                          <div className="info-box-item">
                            <i className="far fa-phone" />
                            <div className="content">
                              <span>Make A Call</span>
                              <br />
                              <a href="callto:+48126563636">+48 12 656 36 36</a>
                            </div>
                          </div>
                        </div>
                      </Fade>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <Fade top>
                      {" "}
                      <div className="about-image-part">
                        <img
                          className="home-about-img"
                          src="/assets/images/about_us_2.png"
                          alt="About Me"
                        />

                        <div className="dot-shape">
                          <img
                            src="assets/images/shape/about-dot.png"
                            alt="Shape"
                          />
                        </div>
                      </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-lines">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          </section>
          <section className="resume-area pt-130 rpt-100 rel z-1">
            <div className="container">
              <div className="row">
                <div className="col-lg-3">
                  <Fade top delay={200}>
                    <div className="big-icon mt-85 rmt-0 rmb-55 ">
                      <img src="/assets/images/idea.png"></img>
                    </div>
                  </Fade>
                </div>
                <div className="col-lg-9">
                  <div className="row">
                    <div className="col-xl-8 col-lg-9">
                      <Fade top delay={200}>
                        {" "}
                        <div className="section-title mb-60 ">
                          <h2>
                            Our Areas of <span>Expertise</span>
                          </h2>
                        </div>
                      </Fade>
                    </div>
                  </div>
                  <div className="resume-items-wrap">
                    <div className="row justify-content-between">
                      <div className="col-xl-5 col-md-6">
                        <Fade top delay={400}>
                          {" "}
                          <div className="resume-item ">
                            <div className="icon">
                              <i className="far fa-arrow-right" />
                            </div>
                            <div className="content">
                              <h4>Energy</h4>
                              <span className="company">
                                At Polentech, our commitment to sustainable
                                growth drives us to continuously explore new
                                horizons.
                              </span>
                            </div>
                          </div>
                        </Fade>
                      </div>
                      <div className="col-xl-5 col-md-6">
                        <Fade top delay={400}>
                          <div className="resume-item ">
                            <div className="icon">
                              <i className="far fa-arrow-right" />
                            </div>
                            <div className="content">
                              <h4>Technology</h4>
                              <span className="company">
                                Using the latest technology, we bring easy and
                                fast solutions suitable for the era.
                              </span>
                            </div>
                          </div>
                        </Fade>
                      </div>
                      <div className="col-xl-5 col-md-6">
                        <Fade top delay={400}>
                          {" "}
                          <div className="resume-item ">
                            <div className="icon">
                              <i className="far fa-arrow-right" />
                            </div>
                            <div className="content">
                              <h4>Innovation</h4>
                              <span className="company">
                                We aim for a leading and pioneering position in
                                the sector with innovative solutions.
                              </span>
                            </div>
                          </div>
                        </Fade>
                      </div>
                      <div className="col-xl-5 col-md-6">
                        <Fade top delay={400}>
                          {" "}
                          <div className="resume-item">
                            <div className="icon">
                              <i className="far fa-arrow-right" />
                            </div>
                            <div className="content">
                              <h4>R&D</h4>
                              <span className="company">
                                We carry out R&D studies in line with the goal
                                of sustainable growth.
                              </span>
                            </div>
                          </div>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-lines">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          </section>
          <section className="services-area pt-130 rpt-100 pb-100 rpb-70 rel z-1">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-8">
                  <Fade bottom delay={200}>
                    {" "}
                    <div className="section-title text-center mb-60 ">
                      <span className="sub-title mb-15">Polentech</span>
                      <h2>
                        Our <span>Commitments</span> and <span>Principles</span>{" "}
                      </h2>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <Fade bottom delay={200}>
                    {" "}
                    <div className="service-item ">
                      <div className="number">01.</div>
                      <div className="content">
                        <h4>Innovation & Technology</h4>
                        <p>We lead with cutting-edge technology.</p>
                      </div>
                    </div>
                  </Fade>
                </div>
                <div className="col-lg-6">
                  <Fade bottom delay={400}>
                    {" "}
                    <div className="service-item ">
                      <div className="number">02.</div>
                      <div className="content">
                        <h4>Sustainability</h4>
                        <p>We prioritize eco-friendly solutions.</p>
                      </div>
                    </div>
                  </Fade>
                </div>
                <div className="col-lg-6">
                  <Fade bottom delay={200}>
                    {" "}
                    <div className="service-item ">
                      <div className="number">03.</div>
                      <div className="content">
                        <h4>Customer Focus</h4>
                        <p>Your satisfaction is paramount.</p>
                      </div>
                    </div>
                  </Fade>
                </div>
                <div className="col-lg-6">
                  <Fade bottom delay={400}>
                    {" "}
                    <div className="service-item ">
                      <div className="number">04.</div>
                      <div className="content">
                        <h4>Reliability</h4>
                        <p>Trust in our high-quality products.</p>
                      </div>
                    </div>
                  </Fade>
                </div>
                <div className="col-lg-6">
                  <Fade bottom delay={400}>
                    {" "}
                    <div className="service-item">
                      <div className="number">05.</div>
                      <div className="content">
                        <h4>Continuous Improvement</h4>
                        <p>We evolve with your needs.</p>
                      </div>
                    </div>
                  </Fade>
                </div>
                <div className="col-lg-6">
                  <Fade bottom delay={400}>
                    {" "}
                    <div className="service-item ">
                      <div className="number">06.</div>
                      <div className="content">
                        <h4>Professionalism</h4>
                        <p>An experienced team at your service.</p>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
            <div className="bg-lines">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          </section>
          <section className="projects-area pt-130 rpt-100 pb-100 rpb-70 rel z-1">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-12">
                  <Fade top delay={200}>
                    <div className="section-title text-center mb-60 ">
                      <span className="sub-title mb-15">Our Solutions</span>
                      <h2>
                        Our <span>Innovative </span>Solutions
                      </h2>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="row align-items-center pb-25">
                <div className="col-lg-6">
                  <Fade left delay={200}>
                    {" "}
                    <div className="project-image ">
                      <img
                        src="assets/images/solutions/energy.webp"
                        alt="Project"
                      />
                    </div>
                  </Fade>
                </div>
                <div className="col-xl-5 col-lg-6">
                  <Fade right delay={200}>
                    <div className="project-content ">
                      <span className="sub-title">polentech</span>
                      <h2>
                        <a href="/services">Energy Solutions</a>
                      </h2>
                      <p>
                        Our Energy Solutions focus on environmental
                        sustainability and energy efficiency. We utilize
                        renewable energy sources such as solar, wind, and
                        hydroelectric power to provide energy production and
                        storage solutions. Additionally, we offer consultancy
                        services for monitoring and managing energy consumption.
                        Our goal is to help organizations reduce their energy
                        costs and minimize their environmental impact while
                        enhancing energy efficiency.
                      </p>
                      <a href="/services" className="details-btn">
                        <i className="far fa-arrow-right" />
                      </a>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="row align-items-center pb-25">
                <div className="col-lg-6 order-lg-2">
                  <Fade left delay={200}>
                    {" "}
                    <div className="project-image ">
                      <img
                        src="assets/images/solutions/image_proc.webp"
                        alt="Project"
                      />
                    </div>
                  </Fade>
                </div>
                <div className="col-xl-5 col-lg-6 ms-auto">
                  <Fade right delay={200}>
                    {" "}
                    <div className="project-content ">
                      <span className="sub-title">polentech</span>
                      <h2>
                        <a href="/services">Image Processing and Evaluation</a>
                      </h2>
                      <p>
                        In the realm of Image Processing and Evaluation, we
                        empower you to make sense of your data. This technology
                        is versatile, finding applications in various sectors
                        like medical diagnostics, autonomous driving systems in
                        the automotive industry, image analysis from security
                        cameras, and more. Leveraging data mining and analytics,
                        we facilitate your access to valuable information,
                        making informed decisions easier.
                      </p>
                      <a href="/services" className="details-btn">
                        <i className="far fa-arrow-right" />
                      </a>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="row align-items-center pb-25">
                <div className="col-lg-6">
                  <Fade left delay={200}>
                    <div className="project-image ">
                      <img
                        src="assets/images/solutions/security.jpg"
                        alt="Project"
                      />
                    </div>
                  </Fade>
                </div>
                <div className="col-xl-5 col-lg-6">
                  <Fade right delay={200}>
                    <div className="project-content ">
                      <span className="sub-title">polentech</span>
                      <h2>
                        <a href="/services">Security Projects</a>
                      </h2>
                      <p>
                        Our Security Projects are designed to protect your
                        business against both physical and cyber threats. Our
                        solutions encompass camera systems, access control,
                        alarm systems, and cybersecurity measures. We provide
                        customized security solutions, effectively reducing
                        risks and bolstering overall security.
                      </p>
                      <a href="/services" className="details-btn">
                        <i className="far fa-arrow-right" />
                      </a>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="row align-items-center pb-25">
                <div className="col-lg-6 order-lg-2">
                  <Fade left delay={200}>
                    {" "}
                    <div className="project-image ">
                      <img
                        src="assets/images/solutions/traffic.webp"
                        alt="Project"
                      />
                    </div>
                  </Fade>
                </div>
                <div className="col-xl-5 col-lg-6 ms-auto">
                  <Fade right delay={200}>
                    <div className="project-content ">
                      <span className="sub-title">polentech</span>
                      <h2>
                        <a href="/services">Traffic Solutions</a>
                      </h2>
                      <p>
                        For Traffic Solutions, we aim to create more orderly and
                        safer urban transportation systems. Technologies such as
                        traffic signalization, traffic density monitoring, smart
                        traffic lights, and driver assistance systems are at the
                        core of our offerings. We optimize traffic flow, helping
                        to reduce congestion and accidents in cities.
                      </p>
                      <a href="/services" className="details-btn">
                        <i className="far fa-arrow-right" />
                      </a>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="row align-items-center pb-25">
                <div className="col-lg-6">
                  <Fade left delay={200}>
                    <div className="project-image ">
                      <img
                        src="assets/images/solutions/health.webp"
                        alt="Project"
                      />
                    </div>
                  </Fade>
                </div>
                <div className="col-xl-5 col-lg-6">
                  <Fade right delay={200}>
                    {" "}
                    <div className="project-content ">
                      <span className="sub-title">polentech</span>
                      <h2>
                        <a href="/services">Health Informatics Solutions</a>
                      </h2>
                      <p>
                        With Health Informatics Solutions, we accelerate the
                        digitization of the healthcare sector. We manage and
                        secure your data, offering electronic health records,
                        patient information management, and streamlined
                        healthcare services. This transformation results in more
                        efficient and coordinated healthcare delivery,
                        ultimately enhancing patient care.
                      </p>
                      <a href="/services" className="details-btn">
                        <i className="far fa-arrow-right" />
                      </a>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="row align-items-center pb-25">
                <div className="col-lg-6 order-lg-2">
                  {" "}
                  <Fade left delay={200}>
                    <div className="project-image ">
                      <img
                        src="assets/images/solutions/constraction.webp"
                        alt="Project"
                      />
                    </div>
                  </Fade>
                </div>
                <div className="col-xl-5 col-lg-6 ms-auto">
                  <Fade right delay={200}>
                    <div className="project-content ">
                      <span className="sub-title">polentech</span>
                      <h2>
                        <a href="/services">Construction and Weak Current </a>
                      </h2>
                      <p>
                        Our Construction and Weak Current solutions strengthen
                        your construction projects. We provide security systems,
                        automation technologies, and other weak current services
                        to enhance efficiency and safety throughout your
                        projects.
                      </p>
                      <a href="/services" className="details-btn">
                        <i className="far fa-arrow-right" />
                      </a>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="row align-items-center pb-25">
                <div className="col-lg-6">
                  <Fade left delay={200}>
                    <div className="project-image ">
                      <img
                        src="assets/images/solutions/industrial.webp"
                        alt="Project"
                      />
                    </div>
                  </Fade>
                </div>
                <div className="col-xl-5 col-lg-6">
                  <Fade right delay={200}>
                    <div className="project-content ">
                      <span className="sub-title">polentech</span>
                      <h2>
                        <a href="/services">Industrial Solutions </a>
                      </h2>
                      <p>
                        In the realm of Industrial Solutions, we support
                        Industry 4.0 transformation and automation. We offer
                        automation, data analytics, and facility monitoring
                        solutions to optimize your manufacturing processes, gain
                        a competitive edge, and reduce costs.
                      </p>
                      <a href="/services" className="details-btn">
                        <i className="far fa-arrow-right" />
                      </a>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="row align-items-center pb-25">
                <div className="col-lg-6 order-lg-2">
                  <Fade left delay={200}>
                    <div className="project-image ">
                      <img
                        src="assets/images/solutions/cyber.webp"
                        alt="Project"
                      />
                    </div>
                  </Fade>
                </div>
                <div className="col-xl-5 col-lg-6 ms-auto">
                  <Fade right delay={200}>
                    <div className="project-content ">
                      <span className="sub-title">polentech</span>
                      <h2>
                        <a href="/services">Cyber Security </a>
                      </h2>
                      <p>
                        Our Cyber Security solutions ensure your business is
                        protected against cyber threats. We secure your
                        networks, conduct penetration testing, provide security
                        consulting, and detect and prevent cyberattacks,
                        safeguarding your data and customer information.
                      </p>
                      <a href="/services" className="details-btn">
                        <i className="far fa-arrow-right" />
                      </a>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="row align-items-center pb-25">
                <div className="col-lg-6">
                  <Fade left delay={200}>
                    <div className="project-image ">
                      <img
                        src="assets/images/solutions/IT.webp"
                        alt="Project"
                      />
                    </div>
                  </Fade>
                </div>
                <div className="col-xl-5 col-lg-6">
                  <Fade right delay={200}>
                    <div className="project-content">
                      <span className="sub-title">polentech</span>
                      <h2>
                        <a href="/services">IT Technologies</a>
                      </h2>
                      <p>
                        Our IT Technologies encompass a wide range of services,
                        including network infrastructure, software development,
                        cloud computing, and other IT services. We empower you
                        to strengthen your technology infrastructure, optimize
                        business processes, and increase efficiency, giving you
                        a competitive edge.
                      </p>
                      <a href="/services" className="details-btn">
                        <i className="far fa-arrow-right" />
                      </a>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="row align-items-center pb-25">
                <div className="col-lg-6 order-lg-2">
                  <Fade left delay={200}>
                    <div className="project-image ">
                      <img
                        src="assets/images/solutions/engineering.webp"
                        alt="Project"
                      />
                    </div>
                  </Fade>
                </div>
                <div className="col-xl-5 col-lg-6 ms-auto">
                  <Fade right delay={200}>
                    <div className="project-content ">
                      <span className="sub-title">polentech</span>
                      <h2>
                        <a href="/services">Engineering Project Services </a>
                      </h2>
                      <p>
                        Our Engineering Project Services are designed to help
                        you successfully complete your projects. We offer
                        consultancy, design, and implementation services,
                        ensuring your projects are delivered on time and within
                        budget.
                      </p>
                      <a href="/services" className="details-btn">
                        <i className="far fa-arrow-right" />
                      </a>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
            <div className="bg-lines">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          </section>
          <section className="contact-area pt-95 pb-130 rpt-70 rpb-100 rel z-1">
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <Fade top delay={200}>
                    <div className="contact-content-part pt-5 rpt-0 rmb-55 ">
                      <div className="section-title mb-40">
                        <span className="sub-title mb-15">
                          Let's Keep In Touch
                        </span>
                        <h2>
                          Your Thoughts Matter, <span>We're Listening</span>
                        </h2>

                        <p>
                          We are here for the latest technology and energy
                          solutions.
                        </p>
                      </div>
                      <ul className="list-style-two">
                        <li>Professionalism</li>
                        <li>Customer Focus</li>
                        <li>Innovation & Technology</li>
                        <li>Sustainability</li>
                      </ul>
                    </div>
                  </Fade>
                </div>
                <div className="col-lg-8">
                  <Fade top delay={400}>
                    <div className="contact-form contact-form-wrap form-style-one ">
                      <form
                        className="contactForm"
                        ref={form}
                        onSubmit={handleSubmit}
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="name">Full Name</label>
                              <input
                                type="text"
                                name="userInfo"
                                className="form-control"
                                placeholder="name"
                                required
                                data-error="Please enter your Name"
                              />
                              <label htmlFor="name" className="for-icon">
                                <i className="far fa-user" />
                              </label>
                              <div className="help-block with-errors" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="email">Email Address</label>
                              <input
                                name="from"
                                className="form-control"
                                placeholder="e-mail"
                                required
                                data-error="Please enter your Email"
                              />
                              <label htmlFor="email" className="for-icon">
                                <i className="far fa-envelope" />
                              </label>
                              <div className="help-block with-errors" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="phone">Phone Number</label>
                              <input
                                name="tel"
                                className="form-control"
                                placeholder="phone"
                                required
                                data-error="Please enter your Phone Number"
                              />
                              <label htmlFor="phone" className="for-icon">
                                <i className="far fa-phone" />
                              </label>
                              <div className="help-block with-errors" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="subject">Subject</label>
                              <input
                                name="subject"
                                className="form-control"
                                placeholder="subject"
                                required
                                data-error="Please enter your Subject"
                              />
                              <label htmlFor="subject" className="for-icon">
                                <i className="far fa-text" />
                              </label>
                              <div className="help-block with-errors" />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="message">Message</label>
                              <textarea
                                name="message"
                                id="message"
                                className="form-control"
                                rows={4}
                                placeholder="write message"
                                required
                                data-error="Please enter your Message"
                              />
                              <div className="help-block with-errors" />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group mb-0">
                              <button
                                type="submit"
                                onSubmit={handleSubmit}
                                className="theme-btn"
                              >
                                Send Us a Message{" "}
                                <i className="far fa-angle-right" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
            <div className="bg-lines">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          </section>
        </>
      )}
    </>
  );
}

export default HomeComponent;
