import React from 'react'

const ServicesComponent = () => {
  return (
    <div> {/* Page Banner Start */}<div className="preloader"></div>
    <div>{/* Page Banner Start */}
  <section className="page-banner-area pt-200 rpt-140 pb-100 rpb-60 rel z-1 text-center">
    <div className="container">
      <div className="banner-inner text-white">
        <h1 className="page-title wow fadeInUp delay-0-2s">Our Solutions</h1>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb justify-content-center wow fadeInUp delay-0-4s">
            <li className="breadcrumb-item"><a href="/">Home</a></li>
            <li className="breadcrumb-item active">Our Solutions</li>
          </ol>
        </nav>
      </div>
    </div>
    <div className="bg-lines">
      <span /><span />
      <span /><span />
      <span /><span />
      <span /><span />
      <span /><span />
    </div>
  </section>
  {/* Page Banner End */}
  {/* Projects Area start */}
  <section className="projects-area pt-40 pb-130 rpb-100 rel z-1">
    <div className="container">
      <ul className="project-filter filter-btns-one justify-content-center pb-35 wow fadeInUp delay-0-2s">
        <li data-filter="*" className="current">Show All</li>
        <li data-filter=".energy">Energy Solutions</li>
        <li data-filter=".security">Security Projects</li>
        <li data-filter=".health">Health Informatics Solutions</li>
        <li data-filter=".indust">Industrial Solutions</li>
        <li data-filter=".it">It Technologies</li>
        <li data-filter=".image">Image Processing and Evaluation</li>
        <li data-filter=".traffic">Traffic Solutions</li>
        <li data-filter=".weak">Construction and Weak</li>
        <li data-filter=".cyber">Cyber Security</li>
        <li data-filter=".engineering">Engineering Project Services</li>
      </ul>
      <div className="row project-masonry-active">
        <div className="col-lg-6 item indust">
          <div className="project-item style-two">
            <div className="project-image">
              <img src="/assets/images/solutions/industrial.webp" alt="Project" />
              
            </div>
            <div className="project-content">
              <span className="sub-title">Industrial Solutions</span>
              <h3>In the realm of Industrial Solutions, we support Industry 4.0 transformation and automation. We offer automation, data analytics, and facility monitoring solutions to optimize your manufacturing processes, gain a competitive edge, and reduce costs.</h3>
            </div>
          </div>
        </div>
        <div className="col-lg-6 item energy">
          <div className="project-item style-two ">
            <div className="project-image">
              <img src="/assets/images/solutions/energy.webp" alt="Project" />
            
            </div>
            <div className="project-content">
              <span className="sub-title">Energy Solutions</span>
              <h3>Our Energy Solutions focus on environmental sustainability and energy efficiency. We utilize renewable energy sources such as solar, wind, and hydroelectric power to provide energy production and storage solutions. Additionally, we offer consultancy services for monitoring and managing energy consumption. Our goal is to help organizations reduce their energy costs and minimize their environmental impact while enhancing energy efficiency.</h3>
            </div>
          </div>
        </div>
        <div className="col-lg-6 item health">
          <div className="project-item style-two">
            <div className="project-image">
              <img src="/assets/images/solutions/health.webp" alt="Project" />
              
            </div>
            <div className="project-content">
              <span className="sub-title">Health Informatics Solutions</span>
              <h3>With Health Informatics Solutions, we accelerate the digitization of the healthcare sector. We manage and secure your data, offering electronic health records, patient information management, and streamlined healthcare services. This transformation results in more efficient and coordinated healthcare delivery, ultimately enhancing patient care.</h3>
            </div>
          </div>
        </div>
        <div className="col-lg-6 item it">
          <div className="project-item style-two">
            <div className="project-image">
              <img src="/assets/images/solutions/IT.webp" alt="Project" />
              
            </div>
            <div className="project-content">
              <span className="sub-title">IT Technologies</span>
              <h3>Our IT Technologies encompass a wide range of services, including network infrastructure, software development, cloud computing, and other IT services. We empower you to strengthen your technology infrastructure, optimize business processes, and increase efficiency, giving you a competitive edge.</h3>
            </div>
          </div>
        </div>
        <div className="col-lg-6 item security">
          <div className="project-item style-two">
            <div className="project-image">
              <img src="/assets/images/solutions/security.jpg" alt="Project" />
              
            </div>
            <div className="project-content">
              <span className="sub-title">Security Projects</span>
              <h3>Our Security Projects are designed to protect your business against both physical and cyber threats. Our solutions encompass camera systems, access control, alarm systems, and cybersecurity measures. We provide customized security solutions, effectively reducing risks and bolstering overall security.</h3>
            </div>
          </div>
        </div>
        <div className="col-lg-6 item image">
          <div className="project-item style-two ">
            <div className="project-image">
              <img src="/assets/images/solutions/image_proc.webp" alt="Project" />
              
            </div>
            <div className="project-content">
              <span className="sub-title">Image Processing and Evaluation</span>
              <h3>In the realm of Image Processing and Evaluation, we empower you to make sense of your data. This technology is versatile, finding applications in various sectors like medical diagnostics, autonomous driving systems in the automotive industry, image analysis from security cameras, and more. Leveraging data mining and analytics, we facilitate your access to valuable information, making informed decisions easier.</h3>
            </div>
          </div>
        </div>
        <div className="col-lg-6 item traffic">
          <div className="project-item style-two">
            <div className="project-image">
              <img src="/assets/images/solutions/traffic.webp" alt="Project" />
              
            </div>
            <div className="project-content">
              <span className="sub-title">Traffic Solutions</span>
              <h3>For Traffic Solutions, we aim to create more orderly and safer urban transportation systems. Technologies such as traffic signalization, traffic density monitoring, smart traffic lights, and driver assistance systems are at the core of our offerings. We optimize traffic flow, helping to reduce congestion and accidents in cities.</h3>
            </div>
          </div>
        </div>
        <div className="col-lg-6 item weak">
          <div className="project-item style-two">
            <div className="project-image">
              <img src="/assets/images/solutions/constraction.webp" alt="Project" />
              
            </div>
            <div className="project-content">
              <span className="sub-title">Construction and Weak</span>
              <h3>Integrating modern technology for stronger construction projects.</h3>
            </div>
          </div>
        </div>
        <div className="col-lg-6 item cyber">
          <div className="project-item style-two">
            <div className="project-image">
              <img src="/assets/images/solutions/cyber.webp" alt="Project" />
              
            </div>
            <div className="project-content">
              <span className="sub-title">Cyber Security</span>
              <h3>Our Cyber Security solutions ensure your business is protected against cyber threats. We secure your networks, conduct penetration testing, provide security consulting, and detect and prevent cyberattacks, safeguarding your data and customer information.</h3>
            </div>
          </div>
        </div>
        <div className="col-lg-6 item engineering">
          <div className="project-item style-two">
            <div className="project-image">
              <img src="/assets/images/solutions/engineering.webp" alt="Project" />
              
            </div>
            <div className="project-content">
              <span className="sub-title">Engineering Project Services</span>
              <h3>Our Engineering Project Services are designed to help you successfully complete your projects. We offer consultancy, design, and implementation services, ensuring your projects are delivered on time and within budget.</h3>
            </div>
          </div>
        </div>
      </div>
    
    </div>
    <div className="bg-lines">
      <span /><span />
      <span /><span />
      <span /><span />
      <span /><span />
      <span /><span />
    </div>
  </section>
  {/* Projects Area end */}</div>

</div>
  )
}

export default ServicesComponent