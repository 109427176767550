import React from 'react'

const PartnerComponent = () => {
  return (
    <div>
      <div className="preloader"></div>
        {/* Page Banner Start */}
    <section className="page-banner-area pt-200 rpt-140 pb-100 rpb-60 rel z-1 text-center">
      <div className="container">
        <div className="banner-inner text-white">
          <h1 className="page-title wow fadeInUp delay-0-2s">Partners</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-center wow fadeInUp delay-0-4s">
              <li className="breadcrumb-item"><a href="/">Home</a></li>
              <li className="breadcrumb-item active">Partners</li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="bg-lines">
        <span /><span />
        <span /><span />
        <span /><span />
        <span /><span />
        <span /><span />
      </div>
    </section>
    {/* Page Banner End */}{/* Skill Area start */}
    <section className="skill-area rel z-1">
      <div className="for-bgc-black pt-130 rpt-100 pb-100 rpb-70">
        <div className="container">
          <div className="row gap-100">
            <div className="col-lg-12 d-flex justify-content-center">
              <div className="skill-content-part rel z-2 rmb-55 wow fadeInUp delay-0-2s">
                <div className="section-title mb-40">
             
                  <h2>Get Inspired by Our  <span>Partners</span></h2>
             
                  <p className='text-center'>Our business partners with whom we grow stronger


</p>
                </div>
                
              </div>
            </div>
            <div className="col-lg-12">
              <div className="skill-items-wrap">
                <div className="row d-flex justify-content-center">
                  {[
                    "aoc.png",
                    "asus.png",
                    "at.png",
                    "aten.png",
                    "axis.png",
                    "brand.png",
                    "check.png",
                    "cisco.png",
                    "comm.png",
                    "corning.png",
                    "dall.png",
                    "dell.png",
                    "dellemc.png",
                    "digitus.png",
                    "fibre.png",
                    "form.png",
                    "fujitsu.png",
                    "gunnebo.png",
                   "alhua.jpeg",
                   "apple.png",
                   "hp.jpg",
                   "ingram.png",
                   "motorola.png",
                    "hikvision.png",
                    "hitachi.png",
                    "kingston.png",
                    "lenovo.png",
                    "milestone.png",,
                    "oracle.png",
                    "planet.png",
                    "prysmian.png",
                    "rm.png",
                    "ruckus.png",
                    "ruijie.png",
                    "seagate.png",
                    "sophos.png",
                    "tdsi.png",
                    "trend.png",
                    "ubiq.png",
                    "vm.png"

                  ].map((image, index) => (
                    <div
                      key={index}
                      className={`col-xl-2 col-lg-4 col-md-3 col-sm-4 col-6 skill-item wow fadeInUp delay-0-${index % 5 + 2}s`}
                    >
                      <img src={`/assets/images/partners/${image}`} alt="Skill" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-lines">
        <span /><span />
        <span /><span />
        <span /><span />
        <span /><span />
        <span /><span />
      </div>
    </section>
    {/* Skill Area end */}</div>
  )
}

export default PartnerComponent