import React from 'react'

const AboutComponent = () => {
  return (
    
   <div>  
    <div className="preloader"></div> 
         {/* Page Banner Start */}
  <section className="page-banner-area pt-200 rpt-140 pb-100 rpb-60 rel z-1 text-center">
    <div className="container">
      <div className="banner-inner text-white">
        <h1 className="page-title wow fadeInUp delay-0-2s">About Us</h1>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb justify-content-center wow fadeInUp delay-0-4s">
            <li className="breadcrumb-item"><a href="/">Home</a></li>
            <li className="breadcrumb-item active">About Us</li>
          </ol>
        </nav>
      </div>
    </div>
    <div className="bg-lines">
      <span /><span />
      <span /><span />
      <span /><span />
      <span /><span />
      <span /><span />
    </div>
  </section>
  {/* Page Banner End */}
  <div className="about-main-image-area pt-40">
    <div className="container">
      <div className="about-main-image wow fadeInUp delay-0-5s">
        <img className='about_img' src="/assets/images/about_us_buyuk.png" alt="About Page" />
      </div>
    </div>
  </div>
  {/* About Page Area start */}
  <section className="about-page-area py-130 rpy-100 rel z-1">
    <div className="container">
      <div className="row align-items-center justify-content-between">
        <div className="col-lg-6">
          <div className="about-page-content-part rel z-2 rmb-55">
            <div className="section-title mb-35 wow fadeInUp delay-0-2s">
              <span className="sub-title mb-15">About Us</span>
              <h2>Where  <span>Innovation </span> Meets <span>Dedication: <br/></span>Polentech</h2>
              <p>Polentech is a dynamic and innovative company dedicated to delivering cutting-edge solutions to meet the unique needs of our diverse clientele. With a team of highly skilled professionals, we excel in various sectors, including Information Technologies, Software Solutions, Energy, Security, Engineering, and Installation.</p>
            </div>
            <ul className="list-style-one two-column pb-30 wow fadeInUp delay-0-2s">
              <li>Energy</li>
              <li>Technology</li>
              <li>Innovation</li>
              <li>R & D</li>
            </ul>
          
          </div>
        </div>
        <div className="col-xl-5 col-lg-6">
          <div className="about-right-part wow fadeInLeft delay-0-3s">
            <div className="experience-years">
             
              <h5>Latest <b>Energy </b>Technology</h5>
            </div>
            <div className="about-btn one wow fadeInRight delay-0-4s">
              <img src="/assets/images/ps_img.png"  alt="Image" />
              <h6>professional staff</h6>
              <i className="fas fa-arrow-right" />
            </div>
            <div className="about-btn two wow fadeInRight delay-0-5s">
              <img src="/assets/images/ps_img.png" alt="Image" />
              <h6>maximum effort</h6>
              <i className="fas fa-arrow-right" />
            </div>
            <div className="about-btn three wow fadeInRight delay-0-4s">
              <img src="/assets/images/ps_img.png"  alt="Image" />
              <h6>modern experience</h6>
              <i className="fas fa-arrow-right" />
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <div className="bg-lines">
      <span /><span />
      <span /><span />
      <span /><span />
      <span /><span />
      <span /><span />
    </div>
  </section>
  {/* About Page Area end */}
  {/* Services Area start */}
  <section className="services-area bgc-black pt-130 rpt-100 pb-100 rpb-70 rel z-1">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-6 col-lg-8">
          <div className="section-title text-center mb-60 wow fadeInUp delay-0-2s">
            
            <h2>Our <span>Vision</span>&<span>Mission</span></h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="service-item wow fadeInUp delay-0-2s">
          
            <div className="content">
              <h4>Our Mission</h4>
              <p>To provide innovative and sustainable energy solutions that power a brighter, greener future.</p>
            </div>
            
          </div>
        </div>
        <div className="col-lg-6">
          <div className="service-item wow fadeInUp delay-0-4s">
           
            <div className="content">
              <h4>Our Vision</h4>
              <p>To lead the way in creating a world where clean, efficient, and responsible energy is accessible to all.</p>
            </div>
            
          </div>
        </div>
    
      </div>
    </div>
    <div className="bg-lines">
      <span /><span />
      <span /><span />
      <span /><span />
      <span /><span />
      <span /><span />
    </div>
  </section>
  {/* Services Area end */}
  {/* FAQs Area start */}
  <section id="faqs" className="faqs-area py-130 rpy-100 rel z-1">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="faq-image-part rmb-55 wow fadeInUp delay-0-2s">
            <div className="image-one">
              <img src="/assets/images/about_us_1.png" alt="FAQ" />
            </div>
            <div className="image-two">
              <img src="/assets/images/world.png" alt="FAQ" />
            </div>
            <div className="square-shape">
              <img src='/assets/images/shape/about-dot.png'></img>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="faq-content-part rel z-2">
            <div className="section-title mb-40 wow fadeInUp delay-0-4s">
              <h2>Discover More<span>  About Us </span></h2>
             </div>
            <div className="accordion wow fadeInUp delay-0-4s" id="faq-accordion">
              <div className="accordion-item">
                <h5 className="accordion-header">
                  <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">
                  What do we do? 
                  </button>
                </h5>
                <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                  <div className="accordion-body">
                    <p>At Polentech, we offer innovative and sustainable solutions in the fields of energy, technology, innovation, and research and development (R&D). We focus on our customers' needs, contributing to clean energy, security, and technology advancements.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h5 className="accordion-header">
                  <button className="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseTwo">
                  Which sectors do we serve?
                  </button>
                </h5>
                <div id="collapseTwo" className="accordion-collapse collapse " data-bs-parent="#faq-accordion">
                  <div className="accordion-body">
                    <p> We operate in various sectors, including energy, technology, innovation, security, healthcare, construction, industry, and information technologies. We provide unique solutions tailored to each sector.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h5 className="accordion-header">
                  <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree">
                  What quality standards do we adhere to?
                  </button>
                </h5>
                <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                  <div className="accordion-body">
                    <p>At Polentech, we are committed to upholding high-quality and reliability standards. Our products and services are carefully produced and tested to ensure customer satisfaction and trust.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h5 className="accordion-header">
                  <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour">
                  How can you reach us?
                  </button>
                </h5>
                <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#faq-accordion">
                  <div className="accordion-body">
                    <p>You can get in touch with us by using our Contact Form to send us a message or through our Contact Information page for phone or email communication.
</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-lines">
      <span /><span />
      <span /><span />
      <span /><span />
      <span /><span />
      <span /><span />
    </div>
  </section>
  {/* FAQs Area end */}

  {/* Client Log start */}

  {/* Client Log end */}</div>

  )
}

export default AboutComponent;