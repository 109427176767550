import React from 'react'

const TrackerComponent = () => {
  return (
    
   <div>  
    <div className="preloader"></div> 
         {/* Page Banner Start */}
  <section className="page-banner-area pt-200 rpt-140 pb-100 rpb-60 rel z-1 text-center">
    <div className="container">
      <div className="banner-inner text-white">
        <h1 className="page-title wow fadeInUp delay-0-2s">Tracker System</h1>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb justify-content-center wow fadeInUp delay-0-4s">
            <li className="breadcrumb-item"><a href="/">Home</a></li>
            <li className="breadcrumb-item active">Axone Duo</li>
          </ol>
        </nav>
      </div>
    </div>
    <div className="bg-lines">
      <span /><span />
      <span /><span />
      <span /><span />
      <span /><span />
      <span /><span />
    </div>
  </section>
  {/* Page Banner End */}
  <div className="about-main-image-area pt-40">
    <div className="container">
      <div className="about-main-image wow fadeInUp delay-0-5s d-flex justify-content-center">
        <div className="col-10"> <div
  className="Sirv"
  data-options={{
    'column.start': 6,
    'row.start': 2,
    'hint.message.enable': false,
    'zoom.enable':false
  }}
  data-src="https://etchurac.sirv.com/polentech/polentech.spin?zoom=1&fullscreen=false&hint=false&autospin=infinite"
></div></div>
     
      </div>
    </div>
  </div>
  {/* About Page Area start */}
  <section className="about-page-area py-130 rpy-100 rel z-1">
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-6">
          <div className="about-page-content-part rel z-2 rmb-55">
            <div className="section-title mb-35 wow fadeInUp delay-0-2s">
              <span className="sub-title mb-15">Axone Duo</span>
              
              <h2>Dual-row <span>tracker Enhanced for</span> XXL modules <span> and Bifacial Technology <br/></span></h2>
              <p>Our single-axis tracker optimizes efficiency with one motor for every two rows. It fuses cutting-edge technology with photovoltaic traditions for the ultimate solar solution.</p>
            </div>
            <ul className="list-style-one two-column pb-30 wow fadeInUp delay-0-2s">
              <li>Cuts Down on Motors and Controllers Compared to Independent-Row Trackers</li>
              <li>Row Alignment Demands Less Precision During Assembly.</li>
              <li>Assembly at Low Heights</li>
              <li>Prepared for Both Bifacial and Monofacial Modules</li>
              <li>Shorter Posts, Resulting in Cost Efficiency</li>
              <li>Waterless Cleaning Robot</li>
            </ul>
          
          </div>
        </div>
        
      </div>
    </div>
    <div className="bg-lines">
      <span /><span />
      <span /><span />
      <span /><span />
      <span /><span />
      <span /><span />
    </div>
  </section>
  {/* About Page Area end */}
  {/* Services Area start */}
  <section className="services-area bgc-black pt-130 rpt-100 pb-100 rpb-70 rel z-1">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-6 col-lg-8">
          <div className="section-title text-center mb-60 wow fadeInUp delay-0-2s">
            
            <h2>Can be Installed on Various Terrains, <span>Withstands Strong </span> <span>Winds and Harsh Climates.</span></h2>
          <p>The Optimal Fusion of AXONE and MONOLINE Solar Trackers' Advantages.</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="service-item wow fadeInUp delay-0-2s">
          
            <div className="content">
              <h4>1V Module Setup</h4>
            
            </div>
            
          </div>
        </div>
        <div className="col-lg-6">
          <div className="service-item wow fadeInUp delay-0-4s">
           
            <div className="content">
              <h4>Controller and Actuator Balance (1x128 Modules)</h4>
            </div>
            
          </div>
        </div>
        <div className="col-lg-6">
          <div className="service-item wow fadeInUp delay-0-4s">
           
            <div className="content">
              <h4>Adaptable to Different Terrain and Irregular Shapes.</h4>
            </div>
            
          </div>
        </div> <div className="col-lg-6">
          <div className="service-item wow fadeInUp delay-0-4s">
           
            <div className="content">
              <h4>Adjustable Posts</h4>
            </div>
            
          </div>
        </div> <div className="col-lg-6">
          <div className="service-item wow fadeInUp delay-0-4s">
           
            <div className="content">
              <h4>Accessible Every Two Rows, Even in Independent-Row Setups</h4>
            </div>
            
          </div>
        </div>
        <div className="col-lg-6">
          <div className="service-item wow fadeInUp delay-0-4s">
           
            <div className="content">
              <h4>Reduced Wind Load
</h4>
            </div>
            
          </div>
        </div>
    
      </div>
    </div>
    <div className="bg-lines">
      <span /><span />
      <span /><span />
      <span /><span />
      <span /><span />
      <span /><span />
    </div>
  </section>
  {/* Services Area end */}
  {/* FAQs Area start */}

  {/* FAQs Area end */}

  {/* Client Log start */}

  {/* Client Log end */}</div>

  )
}

export default TrackerComponent;