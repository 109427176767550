import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";

const ContactComponent = () => {
  const form = useRef();
  const [isSuccess, setIsSuccess] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const encryptedText = CryptoJS.AES.encrypt(
      uuidv4(),
      process.env.REACT_APP_SSK
    ).toString();
    
    const FD = new FormData(form.current);
    FD.append("accessToken", encryptedText);

    const obj = {
      message: FD.get("message"),
      userInfo: FD.get("userInfo"),
      from: FD.get("from"),
      subject: FD.get("subject"),
      tel: FD.get("tel"),
      ownerName: "info@polentech.pl",
      to: "info@polentech.pl",
      host: "smtp.gmail.com",
      service: "gmail",
      accessToken: encryptedText,
    };
    try {
      const res = await axios.post("https://nodemailer.enesatalay.com/", obj, {
        auth: {
          username: "OSICrew_Node_Mailler",
          password: "1786oSi?173",
        },
      });
      if (res) {
        setIsSuccess(true);
        console.log("dkda")
        toast.success("Mesajınız gönderildi.", { autoClose: 4500 });
        console.log(res);
      }
    } catch (error) {
      console.log(error);
      console.log(obj);
      toast.error(error.message, { autoClose: 4500 });
    }
  };
  return (
    <>
      {" "}
      <ToastContainer />
      <div>
        {" "}
        <div className="preloader"></div>
        <section className="page-banner-area pt-200 rpt-140 pb-100 rpb-60 rel z-1 text-center">
          <div className="container">
            <div className="banner-inner text-white">
              <h1 className="page-title wow fadeInUp delay-0-2s">Contact Us</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center wow fadeInUp delay-0-4s">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Contact Us</li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="bg-lines">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
        </section>
        <section className="contact-page pt-40 pb-130 rpb-100 rel z-1">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="contact-page-content rmb-55 wow fadeInUp delay-0-2s">
                  <div className="section-title mb-30">
                    <span className="sub-title mb-15">Let's Keep In Touch</span>
                    <h2>
                      Your Thoughts Matter, <span>We're Listening</span>
                    </h2>

                    <p>
                      We are here for the latest technology and energy solutions
                    </p>
                  </div>
                  <h6>Main Office</h6>
                  <div className="widget_contact_info mb-35">
                    <ul>
                      <li>
                        <i className="far fa-map-marker-alt" /> ul. Marszałkowska 84/92, lok: 2, 00-514 Warszawa - POLSKA
                      </li>
                      <li>
                        <i className="far fa-envelope" />{" "}
                        <a href="mailto:info@polentech.pl">info@polentech.pl</a>
                      </li>
                      <li>
                        <i className="far fa-phone" />{" "}
                        <a href="callto:+48 12 656 36 36">+48 12 656 36 36</a>
                      </li>
                    </ul>
                  </div>
                  <h5>Follow Us</h5>
                  <div className="social-style-one mt-10">
                    <a href="#">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a href="#">
                      <i className="fab fa-twitter" />
                    </a>
                    <a href="#">
                      <i className="fab fa-linkedin-in" />
                    </a>
                    <a href="#">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="contact-page-form contact-form form-style-one wow fadeInUp delay-0-2s">
                  <form
                    className="contactForm"
                    ref={form}
                    onSubmit={handleSubmit}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="name">Full Name</label>
                          <input
                            type="text"
                            name="userInfo"
                            className="form-control"
                            placeholder="name"
                            required
                            data-error="Please enter your Name"
                          />
                          <label htmlFor="name" className="for-icon">
                            <i className="far fa-user" />
                          </label>
                          <div className="help-block with-errors" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="email">Email Address</label>
                          <input
                        
                            name="from"
                            className="form-control"
                            placeholder="e-mail"
                            required
                            data-error="Please enter your Email"
                          />
                          <label htmlFor="email" className="for-icon">
                            <i className="far fa-envelope" />
                          </label>
                          <div className="help-block with-errors" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="phone">Phone Number</label>
                          <input
                          type="tel"
                            name="tel"
                            className="form-control"
                            placeholder="phone"
                            required
                            data-error="Please enter your Phone Number"
                          />
                          <label htmlFor="phone" className="for-icon">
                            <i className="far fa-phone" />
                          </label>
                          <div className="help-block with-errors" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="subject">Subject</label>
                          <input
                            name="subject"
                            className="form-control"
                            placeholder="subject"
                            required
                            data-error="Please enter your Subject"
                          />
                          <label htmlFor="subject" className="for-icon">
                            <i className="far fa-text" />
                          </label>
                          <div className="help-block with-errors" />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="message">Message</label>
                          <textarea
                            name="message"
                            id="message"
                            className="form-control"
                            rows={4}
                            placeholder="write message"
                            required
                            data-error="Please enter your Message"
                          />
                          <div className="help-block with-errors" />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group mb-0">
                          {isSuccess ? null : (
                            <button
                              type="submit"
                              onSubmit={handleSubmit}
                              className="theme-btn"
                            >
                              Send Us a Message{" "}
                              <i className="far fa-angle-right" />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-lines">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
        </section>
        <div className="contact-page-map pb-120 rpb-90 wow fadeInUp delay-0-2s">
          <div className="container">
            <div className="our-location">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5283799.631942632!2d20.6173016!3d49.7111728!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165b71a13557cf%3A0x1db4bf46028f02f5!2sPostal%20Agency%20-%20Krakow!5e0!3m2!1str!2str!4v1698652200004!5m2!1str!2str"
                style={{ border: 0, width: "100%" }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactComponent;
