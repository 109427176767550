import React from "react";
import video from "./panel.webm";
const TestComponent = () => {
  return (
    <div>
      <video
       
     
        preload="auto"
        width={320}
        height={240}
        src={video}
        autoPlay
        loop
        muted
        type="video/quicktime"
      />
    </div>
  );
};

export default TestComponent;
