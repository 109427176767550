import React, { useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ReactHtmlParser from "react-html-parser";

import Fade from "react-reveal/Fade";

const Loader = () => {
  const [count, setCount] = useState(0);
  const [showRightImage, setShowRightImage] = useState(false);
  const [showLeftImage, setShowLeftImage] = useState(false);
  const [showText, setShowText] = useState(false);
  const [showTextTwo, setShowTextTwo] = useState(false);
  const [counterDone, setCounterDone] = useState(false);
  const [text, setText] = useState("polentech");
  const [textTwo, setTextTwo] = useState("energy&nbsp;&amp;&nbsp;technology");


  const [startAnimation, setStartAnimation] = useState(false); // New state variable

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < 100) {
        setCount((prevCount) => prevCount + 25);
      } else {
        clearInterval(interval); 
        setTimeout(() => {
          setShowRightImage(true);
          setShowLeftImage(true);
          setCounterDone(true); 
        }, 100);
        setTimeout(() => {
          setShowText(true);
        }, 500);
        setTimeout(() => {
          setShowTextTwo(true);
        }, 1000);
        setTimeout(() => {
          setStartAnimation(true); 
        }, 4000); 
      }
    }, 300);

    return () => clearInterval(interval);
  }, [count]);

  return (
    <div className="preloaderTwo"> <div className={`loader-main ${counterDone ? "counter-done" : ""} ${startAnimation ? "start-animation" : ""}`}>
    <div className="loader-inner">{showRightImage && (
        <Fade right>
          <img
            src="/assets/images/loader/sag-logo.svg"
            className="right-image"
            alt="Right"
          />
        </Fade>
      )}
      {showLeftImage && (
        <Fade left>
          <img
            src="/assets/images/loader/sol-logo.svg"
            className="left-image"
            alt="Left"
          />
        </Fade>
      )}
      <div className="svg-img">
        {" "}
        {showText && (
          <div className="d-flex justify-content-center">
            {text.split("").map((letter, index) => (
              <Fade key={index} bottom delay={index * 60}>
                <h2 className="bold-logo-tech">{letter}</h2>
              </Fade>
            ))}
          </div>
        )}
       
       {showTextTwo && (
  <div className="d-flex justify-content-center">
    {ReactHtmlParser(textTwo).toString().split("").map((letter, index) => (
      <Fade key={index} bottom delay={index * 30}>
        <h6 className="logo-energy">{letter}</h6>
      </Fade>
    ))}
  </div>
)}

      </div>

      {counterDone ? null : (
        <div className="counter-container">
          <p className="percent-sign">%</p>
          <TransitionGroup className="counter-list">
            <CSSTransition key={count}  classNames="slide">
              <div className="counter-item">
                <Fade>{count}</Fade>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      )}</div>  
    </div></div>
   
  );
};

export default Loader;
