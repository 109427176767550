import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import rootReducer from "./redux/reducers/rootReducer";
import App from './App';
import blogs from "./data/blog.json"
import { fetchBlogs } from "./redux/actions/blogAction";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(applyMiddleware(thunk, save()))
);
store.dispatch(fetchBlogs(blogs));


ReactDOM.render(
  <Provider store={store}>
      
          <App />
  
  </Provider>,
  document.getElementById("root")
);
