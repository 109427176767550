import React from 'react';

const PartnersComponent = () => {
  const clientLogos = [
    { name: 'adac', link: 'https://www.adairports.ae/english/' },
    { name: 'aldar', link: 'https://www.aldar.com/en/haven' },
    { name: 'ar', link: 'https://www.artelecom.pt/' },
    { name: 'dalma', link: 'https://www.dalmamall.ae/' },
    { name: 'emirates', link: 'https://www.emirates.com/tr/english/' },
    { name: 'fedex2', link: 'https://www.fedex.com/en-tr/open-account.html' },
    { name: 'iraq', link: 'https://www.developmentaid.org/organizations/view/428374/iraqi-national-oil-company' },
    { name: 'found', link: 'https://www.brightoncollege.ae/' },
    { name: 'nakheel', link: 'https://www.nakheel.com/en' },
    { name: 'national', link: 'https://www.nh.ae/' },
    { name: 'qatar_fou', link: 'https://www.qf.org.qa/' },
    { name: 'ozbekistan', link: 'https://railway.uz/en/' },
    { name: 'ramada', link: 'https://www.wyndhamhotels.com/ramada' },
    { name: 'Qatar_Rail', link: 'https://corp.qr.com.qa/English/Pages/default.aspx' },
    { name: 'Qatar', link: 'https://qatar-usa.com/' },
    { name: 'siemens', link: 'https://www.siemens.com/global/en.html' },
    { name: 'uzbekistan', link: 'https://railway.uz/en/' },
    { name: 'zerde', link: 'https://development.asia/expert/zerde-holding' },
  ];

  return (
    <div>
            <section className="page-banner-area pt-200 rpt-140 pb-100 rpb-60 rel z-1 text-center">
        <div className="container">
          <div className="banner-inner text-white">
            <h1 className="page-title wow fadeInUp delay-0-2s">References</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center wow fadeInUp delay-0-4s">
                <li className="breadcrumb-item"><a href="/">Home</a></li>
                <li className="breadcrumb-item active">References</li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="bg-lines">
          {[...Array(10)].map((_, index) => (
            <span key={index} />
          ))}
        </div>
      </section>
      <section className="client-logo-area rel z-1 pt-130 rpt-100 pb-60">
        <div className="container">
          <div className="section-title text-center pt-5 mb-65 wow fadeInUp delay-0-2s">
            <h6>Companies we have served so far <span>with success</span></h6>
          </div>
          <div className="client-logo-wrap">
            {clientLogos.map((client, index) => (
              <a target='_blank' href={client.link} key={index} className="client-logo-item wow fadeInUp delay-0-2s">
                <img src={`/assets/images/ref/${client.name}.png`} alt="Client Logo" />
              </a>
            ))}
          </div>
        </div>
        <div className="bg-lines">
          {[...Array(10)].map((_, index) => (
            <span key={index} />
          ))}
        </div>
      </section>
    </div>
  );
};

export default PartnersComponent;
