import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";


import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";

const BlogSingleComponent = ({blogs}) => {
  let { blogNo } = useParams();
  const [blog, setBlog] = useState("");
  const [relatedBlog, setRelatedBlog] = useState("");

  setTimeout(() => (document.title = blog.name), 100);
  useEffect(() => {
      let data = blogs.filter(
          (key) => key.blogNo.toLowerCase() == blogNo.replaceAll(" ", "-")
      );
      if (data.length > 0) {
          setBlog(data[0]);
      }
      let relatedBlog = blogs.filter(
          (key) => key.blogNo.toLowerCase() != blogNo.replaceAll(" ", "-")
      );
      const shuffled = relatedBlog.sort(() => 0.5 - Math.random());
      let selected = shuffled.slice(0, 4);
      if (selected.length > 0) {
          setRelatedBlog(selected);
      }
  }, []);
  return (
    <div>
      <div className="preloader"></div>
      {blog != "" ? (
                  <div> 
                  <section className="page-banner-area pt-200 rpt-140 pb-100 rpb-60 rel z-1 text-center">
                    <div className="container">
                      <div className="banner-inner text-white">
                        <h3 className="page-title "> {blog.name} </h3>
                        <nav aria-label="breadcrumb">
                          <ol className="breadcrumb justify-content-center ">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item active"> {blog.name} </li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                    <div className="bg-lines">
                      <span /><span />
                      <span /><span />
                      <span /><span />
                      <span /><span />
                      <span /><span />
                    </div>
                  </section>
                  <section className="blog-details-area pb-70 rpb-40 pb-130 rpb-100 rel z-1">
                    <div className="container">
                      <div className="row">
                      <div className="col-lg-12">
                   <div className="blog-details-wrap">
                     <div className="content mt-35">
                      
                       <div className="author-date-share mb-40 ">
                         <div className="author">
                           <img src="/assets/images/p_img.png" alt="Author" />
                         </div>
                         <div className="text">
                           <span>Post By</span>
                           <h5>polentech</h5>
                         </div>
                  
                       </div>
                     </div>
                     <div className="image mb-35  d-flex justify-content-center">
                       <img src={blog.img} alt="Blog Details" />
                     </div>
                     <div className="content ">
                       <p className="big-letter"> {ReactHtmlParser(
                                                                    blog.info
                                                                )}</p>
                       
                       <blockquote>
                       {blog.under}
                         <span className="blockquote-footer">polentech</span>
                       </blockquote>
                     </div>
                   
                   </div>
                 </div>
 
                      </div>
                    </div>
                    <div className="bg-lines">
                      <span /><span />
                      <span /><span />
                      <span /><span />
                      <span /><span />
                      <span /><span />
                    </div>
                  </section>
                 </div>
                ) : (
                    <>blog bulunamadı</>
                )}</div>
  )
}

BlogSingleComponent.propTypes = {
  blogs: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
      blogs: state.blogData.blogs,
  };
};

export default connect(mapStateToProps)(BlogSingleComponent);