import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import HomeComponent from "./components/HomeComponent";
import Header from "./wrappers/Header";
import { Footer } from "./wrappers/Footer";
import AboutComponent from "./components/AboutComponent";
import ServicesComponent from "./components/ServicesComponent";
import BlogComponent from "./components/BlogComponent";
import ContactComponent from "./components/ContactComponent";
import RefComponent from "./components/RefComponent";
import BlogSingleComponent from "./components/BlogSingleComponent";
import { changeLanguage } from "redux-multilanguage";
import PropTypes from "prop-types";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import Loader from "./components/Loader";
import PartnerComponent from "./components/PartnerComponent";
import TrackerComponent from "./components/TrackerComponent";
import TestComponent from "./components/TestComponent";
const App = (props) => {

  props.dispatch(
    loadLanguages({
      languages: {
        en: require("./translation/en.json"),
        pl: require("./translation/pl.json"),
      },
    })
  );

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route exact path="/" element={<HomeComponent />} />
        <Route exact path="/test" element={<TestComponent />} />
        <Route exact path="/about" element={<AboutComponent />} />
        <Route exact path="/services" element={<ServicesComponent />} />
        <Route exact path="/blog" element={<BlogComponent />} />
        <Route
          exact
          path="/blog-details/:blogNo"
          element={<BlogSingleComponent />}
        />
        <Route exact path="/contact" element={<ContactComponent />} />
        <Route exact path="/partners" element={<PartnerComponent />} />
        <Route exact path="/references" element={<RefComponent />} />
        <Route exact path="/tracker-system" element={<TrackerComponent />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

export default connect()(multilanguage(App));
