import React from 'react';
import { changeLanguage } from 'redux-multilanguage';
import PropTypes from 'prop-types';
import { multilanguage } from 'redux-multilanguage';
import { connect } from 'react-redux';

const Header = (props) => {
  const handleLanguageChange = (languageCode) => {
    props.dispatch(changeLanguage(languageCode));
  };

  return (
    <>
      {/* <div className="preloader"></div> */}
      <header className="main-header menu-absolute">
        {/* Header-Upper */}
        <div className="header-upper">
          <div className="container container-1620 clearfix">
            <div className="header-inner rel d-flex align-items-center">
              <div className="logo-outer">
                <div className="logo">
                  <a href="/">
                    <img
                      className='header-logo'
                      src="/assets/images/logos/logo-white.svg"
                      alt="Logo"
                      title="Logo"
                    />
                  </a>
                </div>
              </div>
              <div className="nav-outer clearfix mx-auto-2">
                {/* Main Menu */}
                <nav className="main-menu navbar-expand-lg">
                <div className="mobile-switcher">
                      <div className="language-buttons">
                        <button
                          onClick={() => handleLanguageChange('en')}
                          className={`language-button ${
                            props.currentLanguageCode === 'en' ? 'active' : ''
                          }`}
                        >
                          EN
                        </button>
                        <button
                          onClick={() => handleLanguageChange('pl')}
                          className={`language-button ${
                            props.currentLanguageCode === 'pl' ? 'active' : ''
                          }`}
                        >
                          PL
                        </button>
                      </div>
                    </div>
                  <div className="navbar-header">
                    <div className="mobile-logo my-15">
                      <a href="/">
                        <img
                          src="/assets/images/logos/logo-white.svg"
                          alt="Logo"
                          title="Logo"
                        />
                      </a>
                    </div>
                    {/* Toggle Button */}
                    <button
                      type="button"
                      className="navbar-toggle me-4"
                      data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse"
                    >
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                  </div>
                  <div className="navbar-collapse collapse clearfix">
                    <ul className="navigation clearfix">
                      <li>
                        <a href="/">home</a>
                      </li>
                      <li>
                        <a href="/about">about</a>
                      </li>
                      <li class="dropdown">
                        <a href="/services">solutions</a>
                        <ul>
                        <li><a href="/services">All Solutions</a></li>          <li><a href="/tracker-system">Tracker System</a></li>
                                               
                                            </ul>
                      </li>
                 
                      <li>
                        <a href="/references">references</a>
                      </li>
                      <li>
                        <a href="/partners">partners</a>
                      </li>
                      <li>
                        <a href="/blog">blog</a>
                      </li>
                      <li>
                        <a href="/contact">Contact</a>
                      </li>
                    </ul>
                    <div className="d-flex justify-content-end web-switcher">
                      <div className="language-buttons">
                        <button
                          onClick={() => handleLanguageChange('en')}
                          className={`language-button ${
                            props.currentLanguageCode === 'en' ? 'active' : ''
                          }`}
                        >
                          EN
                        </button>
                        <button
                          onClick={() => handleLanguageChange('pl')}
                          className={`language-button ${
                            props.currentLanguageCode === 'pl' ? 'active' : ''
                          }`}
                        >
                          PL
                        </button>
                      </div>
                    </div>
                  </div>
                </nav>
                {/* Main Menu End */}
              </div>
            </div>
          </div>
        </div>
        {/* End Header Upper */}
      </header>
    </>
  );
};

Header.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};

export default connect()(multilanguage(Header));
