import React,{useState,useEffect} from 'react';
import Pagination from "rc-pagination";
import { connect } from "react-redux";
import PropTypes from "prop-types";
const BlogComponent = ({blogs}) => {
  const [collection, setCollection] = useState([]);
  const [resultLast, setResultLast] = useState([]);
  const [name, setName] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(9);
  useEffect(() => {
      setCollection(blogs);
      setResultLast(blogs.slice(0, count));
      let arr = [];
      let seen = new Set(),
          result = blogs.filter(({ name }) => seen.add(name)),
          name = result.map((key) => {
              const obj = {
                  name: key.name,
                  img: key.img,
                  info: key.info,
                  blogNo: key.blogNo,
              };
              arr.push(obj);
          });

      setName(arr);
  }, []);

  const updatePage = (pageNumber) => {
      const to = count * pageNumber;
      const from = to - count;
      setCurrentPage(pageNumber);
      setResultLast(collection.slice(from, to));
  };
  return (
   <div> 
    <div className="preloader"></div>
  <section className="page-banner-area pt-200 rpt-140 pb-100 rpb-60 rel z-1 text-center">
    <div className="container">
      <div className="banner-inner text-white">
        <h1 className="page-title wow fadeInUp delay-0-2s"> Blog</h1>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb justify-content-center wow fadeInUp delay-0-4s">
            <li className="breadcrumb-item"><a href="/">Home</a></li>
            <li className="breadcrumb-item active"> Blog</li>
          </ol>
        </nav>
      </div>
    </div>
    <div className="bg-lines">
      <span /><span />
      <span /><span />
      <span /><span />
      <span /><span />
      <span /><span />
    </div>
  </section>
  <section className="blog-standard-area pb-70 rpb-40 pb-130 rpb-100  z-1">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="blog-standard-wrap">
            <div className="row">
            {resultLast.map((key, index) => {
                                return (
                                    <div key={index} className="col-md-6 item">
                <div className="blog-item style-two ">
                  <div className="image">
                    <img src={key.img} alt="Blog Standard" />
                  </div>
                  <div className="content">
                   
                    <h5><a  href={`/blog-details/${key.blogNo}`}>{key.name} </a></h5>
                    <hr />
                    <div className="blog-meta mb-5">
                      <a className="date" href="#"><i className="far fa-calendar-alt" /> polentech</a>
                    </div>
                  </div>
                </div>
              </div>
                                );
                            })}
            </div>
          </div>
        </div>
        
      </div>
    </div>
    <div className="bg-lines">
      <span /><span />
      <span /><span />
      <span /><span />
      <span /><span />
      <span /><span />
    </div>
  </section>
  </div>

  )
}

BlogComponent.propTypes = {
  blogs: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
      blogs: state.blogData.blogs,
  };
};

export default connect(mapStateToProps)(BlogComponent);