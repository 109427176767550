import React from 'react'

export const Footer = () => {
  return (
 <div>
  <footer className="main-footer rel z-1">
    <div className="footer-top-wrap bgc-black pt-100 pb-75">
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-12">
            <div className="footer-widget widget_logo wow fadeInUp delay-0-2s">
              <div className="footer-logo d-flex justify-content-center">
                <a href="/"><img style={{height:"10vh"}} src="/assets/images/logos/logo-white.svg" alt="Logo" /></a>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-7">
            <div className="footer-widget widget_nav_menu wow fadeInUp delay-0-4s">
              {/* <h6 className="footer-title">Quick Link</h6> */}
              <ul className='d-flex justify-content-center'>
                <li><a href="/">Home</a></li>
                <li><a href="/about">About</a></li>
                <li><a href="/services">Solutions</a></li>
                <li><a href="/references">References</a></li>
                <li><a href="/partners">Partners</a></li>
                <li><a href="/blog">Blog</a></li>
                <li><a href="/contact">Contact</a></li>
              </ul>
            </div>
           
          </div>
          <div className="col-lg-3 col-md-5">
            <div className="footer-widget widget_contact_info wow fadeInUp delay-0-6s">
              <h6 className="footer-title">Address</h6>
              <ul>
                <li><i className="far fa-map-marker-alt" /> ul. Marszałkowska 84/92, lok: 2, 00-514 Warszawa - POLSKA</li>
                <li><i className="far fa-envelope" /> <a href="mailto:info@polentech.pl">info@polentech.pl</a></li>
                <li><i className="far fa-phone" /> <a href="callto:+48126563636">+48 12 656 36 36</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-bottom pt-20 pb-5 rpt-25">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="copyright-text">
              <p>Copyright @2023, <a href="https://osicrew.com/">OsiCrew</a> All Rights Reserved</p>
            </div>
          </div>
          <div className="col-lg-6 text-lg-end">
            <ul className="footer-bottom-nav">
              <li><a href="#">Facebook</a></li>
              <li><a href="#">Twitter</a></li>
              <li><a href="#">Instagram</a></li>
              <li><a href="#">LinkedIn</a></li>
            </ul>
          </div>
        </div>
        {/* Scroll Top Button */}
        <button className="scroll-top scroll-to-target" data-target="html"><span className="fas fa-angle-double-up" /></button>
      </div>
      <div className="bg-lines">
        <span /><span />
        <span /><span />
        <span /><span />
        <span /><span />
        <span /><span />
      </div>
    </div>
  </footer></div>

  )
}
